import React, { Fragment, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";

import { Helmet } from "react-helmet";
import { getEnding } from "../../../../helpers";
import Separator from "../../../../nekrasovka-ui/Separator";
import Button from "../../../../nekrasovka-ui/Button";
import axios from "axios";
import { connect } from "react-redux";

const GlobalStyles = createGlobalStyle`
@media (max-width: 700px) {
  header {
    display: none !important;
  };
}
`;

const NewsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background-color: white;

  @media (min-width: 701px) {
    border-radius: 5px;
    padding: 20px 15px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const NewsCardHeader = styled.div`
  display: flex;
  column-gap: 8px;

  > div {
    :nth-child(1) {
      display: flex;
      align-items: center;

      img {
        height: 50px;
        width: 50px;
        border-radius: 5px;
      }
    }

    :nth-child(2) {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      span {
        :nth-child(1) {
          font-size: 14px;
        }

        :nth-child(2) {
          font-size: 11px;
          color: rgb(119, 119, 119);
        }
      }
    }
  }
`;

const NewsCardBody = styled.div`
  margin-top: 20px;
  word-break: break-word;

  .date-past,
  .date-past ~ a {
    display: none;
  }

  > div {
    img {
      max-width: 830px;
    }
  }

  h3 {
    font-size: 16px;
    margin: 0 0 15px;
    font-weight: 500;
  }

  > div {
    * {
      font-size: 14px;
    }
  }
`;

const NewsCardAction = styled.div`
  display: flex;
  font-size: 11px;
  color: rgb(119, 119, 119);
  line-height: 1;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background-color: rgba(240, 240, 240, 0.4);
      color: #222222;
    }
  }
`;

const NewsCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const NewsCardFooterLabels = styled.div`
  display: flex;

  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgb(240, 240, 240);
`;

const NewsCardFooterCommentsSize = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
`;

const NewsCardHeaderClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  width: 27px;
  font-weight: bold;
  margin-left: auto;
  border-radius: 50%;
  color: rgb(119, 119, 119);

  @media (hover: hover) {
    cursor: pointer;

    :hover {
      background-color: rgb(240, 240, 240);
    }
  }
`;

const NewsCardCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 14px;

    > div:nth-child(1) {
      display: flex;
      column-gap: 8px;
      align-items: center;
      background: rgb(240 240 240 / 40%);
      border-radius: 5px;
      padding: 5px;

      img {
        height: 32px;
        width: 32px;
        border-radius: 5px;
      }
    }
  }
`;

const NewsCardActionContainer = styled.div`
  display: flex;
  column-gap: 15px;
`;

const NewsCarAddComment = styled.textarea`
  border: 1px solid rgb(196, 196, 196);
  padding: 10px 18px;
`;

const NewsCarAddCommentButtons = styled.div`
  column-gap: 15px;

  button {
    border-radius: 5px;
  }

  @media (min-width: 701px) {
    display: flex;
  }

  @media (max-width: 700px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      width: 100%;
    }
  }
`;

const NewsCardSeparator = styled(Separator)`
  max-width: 100%;
`;

const NewsCardExtended = ({
  card,
  handleNewsCard,
  preferred_username,
  checkTokenExpiredYesUpdate,
}) => {
  const canEdit = card.history.createdBy.username === preferred_username;
  const isMetadata = !!card.metadata.labels.results.length;
  const isBody = !!card.body;
  const [editingComment, setEditingComment] = useState({});
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isNewComment, setIsNewComment] = useState(false);
  const [comments, setComments] = useState({
    data: [],
    size: "0",
    isLoading: false,
  });

  const handleAddComment = (value) => {
    setNewComment(value);
    setIsNewComment(true);
  };

  const resetAddedComment = () => {
    setNewComment("");
    setIsNewComment(false);
  };

  const handleEditComment = (data) => {
    setIsCommentEdit(true);
    setEditingComment({ ...data, value: data.value.replace(/<[^>]*>/g, "") });
  };

  const resetEditedComment = () => {
    setIsCommentEdit(false);
    setEditingComment({});
  };

  const saveEditedComment = async () => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };

    const url = `${process.env.REACT_APP_API}/comment`;
    const options = {
      method: "put",
      data: {
        text: `<p>${editingComment.value}</p>`,
        id: editingComment.id,
        version: editingComment.version + 1,
      },
      url,
      headers,
    };

    await axios(options);

    const data = [...comments.data].map((comment) => {
      if (comment.id === editingComment.id)
        return { ...comment, value: `<p>${editingComment.value}</p>` };
      return comment;
    });

    setComments({
      ...comments,
      data,
    });

    resetEditedComment();
  };

  const saveComment = async () => {
    resetAddedComment();

    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/comment`;
    const options = {
      method: "post",
      data: { text: `<p>${newComment}</p>`, id: card.id },
      url,
      headers,
    };

    const response = await axios(options);

    setComments({
      ...comments,
      size: comments.size + 1,
      data: [
        ...comments.data,
        {
          value: response.data.body.storage.value,
          email: response.data.history.createdBy.email,
          version: 1,
          name: {
            displayName: response.data.history.createdBy.displayName,
            username: response.data.history.createdBy.username,
          },
        },
      ],
    });
  };

  const deleteComment = async (id) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };

    const url = `${process.env.REACT_APP_API}/comment`;
    const options = {
      method: "delete",
      data: { id },
      url,
      headers,
    };

    await axios(options);

    setComments({
      ...comments,
      size: comments.size - 1,
      data: comments.data.filter((comment) => comment.id !== id),
    });
  };

  useEffect(() => {
    setComments({
      data: card.children.comment.results.map((comment) => {
        return {
          id: comment.id,
          value: comment.body.storage.value,
          email: comment.history.createdBy.email,
          version: comment.version.number,
          name: {
            displayName: comment.history.createdBy.displayName,
            username: comment.history.createdBy.username,
          },
        };
      }),
      size: card.children.comment.size,
      isLoading: true,
    });
  }, []);

  return (
    <Fragment>
      <Fragment>
        <GlobalStyles />
        <Helmet>
          <meta name="theme-color" content="white" />
        </Helmet>
      </Fragment>
      <NewsCardContainer>
        <NewsCardHeader>
          <div>
            <img
              src={`https://filin.mail.ru/pic?from=ph&email=${card.history.createdBy.email}&width=180&height=180`}
              alt=""
            />
          </div>
          <div>
            <span>{card.history.createdBy.displayName}</span>
            <span>
              {format(new Date(card.history.createdDate), "dd.MM.yyyy", {
                locale,
              })}
            </span>
          </div>
          <NewsCardHeaderClose onClick={() => handleNewsCard("")}>
            &#x2715;
          </NewsCardHeaderClose>
        </NewsCardHeader>
        <NewsCardBody>
          <h3>{card.title}</h3>
          {isBody && (
            <div dangerouslySetInnerHTML={{ __html: card.body.view.value }} />
          )}
          {canEdit && (
            <NewsCardAction
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://team.nekrasovka.ru/confluence/${card._links.edit}`}
            >
              редактировать
            </NewsCardAction>
          )}
        </NewsCardBody>
        <NewsCardFooter>
          {isMetadata && (
            <NewsCardFooterLabels>
              {card.metadata.labels.results.map((meta, index) => (
                <div key={index}>{meta.name}</div>
              ))}
            </NewsCardFooterLabels>
          )}
          <NewsCardFooterCommentsSize>
            {card.children.comment.size}
            {` ${getEnding(card.children.comment.size)}`}
          </NewsCardFooterCommentsSize>
        </NewsCardFooter>
        <Fragment>
          <NewsCardSeparator />
          <NewsCardCommentsContainer>
            {comments.data.map((item) => {
              return (
                <div key={item.id}>
                  <div>
                    <img
                      src={`https://filin.mail.ru/pic?from=ph&email=${item.email}&width=180&height=180`}
                      alt=""
                    />
                    <span>{item.name.displayName}</span>
                  </div>
                  {isCommentEdit && editingComment.id === item.id ? (
                    <NewsCarAddComment
                      value={editingComment.value}
                      onChange={(e) =>
                        setEditingComment({
                          ...editingComment,
                          value: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: item.value }} />
                  )}
                  {preferred_username === item.name.username &&
                    (isCommentEdit && editingComment.id === item.id ? (
                      <NewsCarAddCommentButtons>
                        <Button name="Сохранить" onClick={saveEditedComment} />
                        <Button name="Отменить" onClick={resetEditedComment} />
                      </NewsCarAddCommentButtons>
                    ) : (
                      <NewsCardActionContainer>
                        <NewsCardAction onClick={() => handleEditComment(item)}>
                          редактировать
                        </NewsCardAction>
                        <NewsCardAction onClick={() => deleteComment(item.id)}>
                          удалить
                        </NewsCardAction>
                      </NewsCardActionContainer>
                    ))}
                </div>
              );
            })}
          </NewsCardCommentsContainer>
          {!isCommentEdit && (
            <Fragment>
              <NewsCarAddComment
                placeholder="Написать комментарий..."
                value={newComment}
                onChange={(e) => handleAddComment(e.target.value)}
              />
              {isNewComment && (
                <NewsCarAddCommentButtons>
                  <Button name="Сохранить" onClick={saveComment} />
                  <Button name="Отмегить" onClick={resetAddedComment} />
                </NewsCarAddCommentButtons>
              )}
            </Fragment>
          )}
        </Fragment>
      </NewsCardContainer>
    </Fragment>
  );
};

const mapStateToProps = ({ allowance: { checkTokenExpiredYesUpdate } }) => ({
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps)(NewsCardExtended);
