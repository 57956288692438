import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";

import Navigation from "./components/Navigation";
import Departments from "./containers/Departments";
import Users from "./containers/Users";
import User from "./containers/User";
import Websites from "./containers/Websites";
import News from "./containers/News";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../reducers/progress/actions";
import { mapOrder } from "../../helpers";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import Services from "./containers/Services";
import { Helmet } from "react-helmet";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import Mail from "./containers/Mail";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const initialUser = {
  data: {
    name: "",
    login: "",
    status: "",
    email: "",
    apps: "",
    department: { login: "", name: "" },
  },
  loaded: false,
};

const initialMembers = {
  data: [],
  loaded: false,
};

const initialDepartments = {
  data: [],
  loaded: false,
};

const initialServices = {
  data: [],
  loaded: false,
};

const initialFavorites = {
  default: {
    id: 26,
    name: "GLPI",
    description:
      "Сервис для техподдержки ОИТ и ЭХО и учета программного обеспечения",
    url: "https://glpi.nekrasovka.ru/",
    roles: "[1, 2]",
    is_external: 1,
  },
  data: [],
  loaded: false,
};

const initialWebsites = {
  data: [],
  loaded: false,
};

const initialNews = {
  data: [],
  loaded: false,
};

const initialIssues = {
  data: [],
  loaded: false,
};

const initialNewsCard = {
  data: {},
  loaded: false,
};

const initialSignature = {
  data: {
    fullname: "",
    title: "",
    department: "",
    internal_phone: "",
    alternative_phone: "",
    alternative_email: "",
    telegram: "",
  },
  loaded: false,
};

const initialSearch = {
  members: [],
  departments: [],
  services: [],
  websites: [],
};

const phones = ["+7 495 916 9386", "+7 499 261 1912", "+7 495 916 9068"];
const countryCodes = [
  { country: "Австралия", code: "+61" },
  { country: "Австрия", code: "+43" },
  { country: "Азербайджан", code: "+994" },
  { country: "Албания", code: "+355" },
  { country: "Алжир", code: "+213" },
  { country: "Ангола", code: "+244" },
  { country: "Андорра", code: "+376" },
  { country: "Антигуа и Барбуда", code: "+1268" },
  { country: "Аргентина", code: "+54" },
  { country: "Армения", code: "+374" },
  { country: "Аруба", code: "+297" },
  { country: "Багамы", code: "+1242" },
  { country: "Бангладеш", code: "+880" },
  { country: "Барбадос", code: "+1246" },
  { country: "Бахрейн", code: "+973" },
  { country: "Беларусь", code: "+375" },
  { country: "Белиз", code: "+501" },
  { country: "Бельгия", code: "+32" },
  { country: "Бенин", code: "+229" },
  { country: "Бермуды", code: "+1441" },
  { country: "Болгария", code: "+359" },
  { country: "Боливия", code: "+591" },
  { country: "Босния и Герцеговина", code: "+387" },
  { country: "Ботсвана", code: "+267" },
  { country: "Бразилия", code: "+55" },
  { country: "Бруней", code: "+673" },
  { country: "Буркина-Фасо", code: "+226" },
  { country: "Бурунди", code: "+257" },
  { country: "Вануату", code: "+678" },
  { country: "Великобритания", code: "+44" },
  { country: "Венгрия", code: "+36" },
  { country: "Венесуэла", code: "+58" },
  { country: "Вьетнам", code: "+84" },
  { country: "Габон", code: "+241" },
  { country: "Гаити", code: "+509" },
  { country: "Гайана", code: "+592" },
  { country: "Гамбия", code: "+220" },
  { country: "Гана", code: "+233" },
  { country: "Гватемала", code: "+502" },
  { country: "Гвинея", code: "+224" },
  { country: "Гвинея-Бисау", code: "+245" },
  { country: "Германия", code: "+49" },
  { country: "Гондурас", code: "+504" },
  { country: "Гонконг", code: "+852" },
  { country: "Гренада", code: "+1473" },
  { country: "Греция", code: "+30" },
  { country: "Грузия", code: "+995" },
  { country: "Дания", code: "+45" },
  { country: "Джибути", code: "+253" },
  { country: "Доминика", code: "+1767" },
  { country: "Доминиканская республика", code: "+1-809" },
  { country: "Египет", code: "+20" },
  { country: "Замбия", code: "+260" },
  { country: "Западная Сахара", code: "+212" },
  { country: "Зимбабве", code: "+263" },
  { country: "Израиль", code: "+972" },
  { country: "Индия", code: "+91" },
  { country: "Индонезия", code: "+62" },
  { country: "Иордания", code: "+962" },
  { country: "Ирак", code: "+964" },
  { country: "Иран", code: "+98" },
  { country: "Ирландия", code: "+353" },
  { country: "Исландия", code: "+354" },
  { country: "Испания", code: "+34" },
  { country: "Италия", code: "+39" },
  { country: "Йемен", code: "+967" },
  { country: "Кабо-Верде", code: "+238" },
  { country: "Камбоджа", code: "+855" },
  { country: "Камерун", code: "+237" },
  { country: "Канада", code: "+1" },
  { country: "Катар", code: "+974" },
  { country: "Кения", code: "+254" },
  { country: "Кипр", code: "+357" },
  { country: "Кирибати", code: "+686" },
  { country: "Китай", code: "+86" },
  { country: "Колумбия", code: "+57" },
  { country: "Коморы", code: "+269" },
  { country: "Конго (ДРК)", code: "+243" },
  { country: "Конго (Республика)", code: "+242" },
  { country: "Коста-Рика", code: "+506" },
  { country: "Куба", code: "+53" },
  { country: "Кувейт", code: "+965" },
  { country: "Лаос", code: "+856" },
  { country: "Латвия", code: "+371" },
  { country: "Лесото", code: "+266" },
  { country: "Либерия", code: "+231" },
  { country: "Ливан", code: "+961" },
  { country: "Ливия", code: "+218" },
  { country: "Литва", code: "+370" },
  { country: "Люксембург", code: "+352" },
  { country: "Маврикий", code: "+230" },
  { country: "Мавритания", code: "+222" },
  { country: "Мадагаскар", code: "+261" },
  { country: "Македония (Северная)", code: "+389" },
  { country: "Малайзия", code: "+60" },
  { country: "Малави", code: "+265" },
  { country: "Мальдивы", code: "+960" },
  { country: "Мальта", code: "+356" },
  { country: "Марокко", code: "+212" },
  { country: "Маршалловы Острова", code: "+692" },
  { country: "Мексика", code: "+52" },
  { country: "Мозамбик", code: "+258" },
  { country: "Молдова", code: "+373" },
  { country: "Монако", code: "+377" },
  { country: "Монголия", code: "+976" },
  { country: "Мьянма", code: "+95" },
  { country: "Намибия", code: "+264" },
  { country: "Науру", code: "+674" },
  { country: "Непал", code: "+977" },
  { country: "Нигер", code: "+227" },
  { country: "Нигерия", code: "+234" },
  { country: "Нидерланды", code: "+31" },
  { country: "Никарагуа", code: "+505" },
  { country: "Ниуэ", code: "+683" },
  { country: "Новая Зеландия", code: "+64" },
  { country: "Норвегия", code: "+47" },
  { country: "ОАЭ", code: "+971" },
  { country: "Оман", code: "+968" },
  { country: "Пакистан", code: "+92" },
  { country: "Палау", code: "+680" },
  { country: "Палестина", code: "+970" },
  { country: "Панама", code: "+507" },
  { country: "Папуа — Новая Гвинея", code: "+675" },
  { country: "Парагвай", code: "+595" },
  { country: "Перу", code: "+51" },
  { country: "Польша", code: "+48" },
  { country: "Португалия", code: "+351" },
  { country: "Россия", code: "+7" },
  { country: "Руанда", code: "+250" },
  { country: "Румыния", code: "+40" },
  { country: "Сальвадор", code: "+503" },
  { country: "Самоа", code: "+685" },
  { country: "Сан-Марино", code: "+378" },
  { country: "Сан-Томе и Принсипи", code: "+239" },
  { country: "Саудовская Аравия", code: "+966" },
  { country: "Свазиленд", code: "+268" },
  { country: "Сейшелы", code: "+248" },
  { country: "Сенегал", code: "+221" },
  { country: "Сент-Винсент и Гренадины", code: "+1784" },
  { country: "Сент-Китс и Невис", code: "+1869" },
  { country: "Сент-Люсия", code: "+1758" },
  { country: "Сербия", code: "+381" },
  { country: "Сингапур", code: "+65" },
  { country: "Сирия", code: "+963" },
  { country: "Словакия", code: "+421" },
  { country: "Словения", code: "+386" },
  { country: "Соломоновы Острова", code: "+677" },
  { country: "Сомали", code: "+252" },
  { country: "Судан", code: "+249" },
  { country: "Суринам", code: "+597" },
  { country: "США", code: "+1" },
  { country: "Таджикистан", code: "+992" },
  { country: "Таиланд", code: "+66" },
  { country: "Танзания", code: "+255" },
  { country: "Того", code: "+228" },
  { country: "Тонга", code: "+676" },
  { country: "Тринидад и Тобаго", code: "+1868" },
  { country: "Тувалу", code: "+688" },
  { country: "Тунис", code: "+216" },
  { country: "Туркмения", code: "+993" },
  { country: "Турция", code: "+90" },
  { country: "Уганда", code: "+256" },
  { country: "Узбекистан", code: "+998" },
  { country: "Украина", code: "+380" },
  { country: "Уругвай", code: "+598" },
  { country: "Фиджи", code: "+679" },
  { country: "Филиппины", code: "+63" },
  { country: "Финляндия", code: "+358" },
  { country: "Франция", code: "+33" },
  { country: "Хорватия", code: "+385" },
  { country: "Чад", code: "+235" },
  { country: "Черногория", code: "+382" },
  { country: "Чехия", code: "+420" },
  { country: "Чили", code: "+56" },
  { country: "Швейцария", code: "+41" },
  { country: "Швеция", code: "+46" },
  { country: "Шри-Ланка", code: "+94" },
  { country: "Эквадор", code: "+593" },
  { country: "Экваториальная Гвинея", code: "+240" },
  { country: "Эритрея", code: "+291" },
  { country: "Эстония", code: "+372" },
  { country: "Эфиопия", code: "+251" },
  { country: "Южная Африка", code: "+27" },
  { country: "Южный Судан", code: "+211" },
  { country: "Ямайка", code: "+1876" },
  { country: "Япония", code: "+81" },
];

const initialCountryCodeId = 127;

const Staff = ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
}) => {
  const { dispatch } = useContext(AlertContext);

  const draggedItemIndex = useRef();
  const draggedOverItemIndex = useRef();
  const today = new Date();

  const { pathname } = useLocation();
  const history = useHistory();
  const { user_id, department_id, news_id } = useParams();

  const [query, setQuery] = useState("");
  const [search, setSearch] = useState(initialSearch);
  const [user, setUser] = useState(initialUser);
  const [members, setMembers] = useState(initialMembers);
  const [departments, setDepartments] = useState(initialDepartments);
  const [services, setServices] = useState(initialServices);
  const [signature, setSignature] = useState(initialSignature);
  const [favorites, setFavorites] = useState(initialFavorites);
  const [websites, setWebsites] = useState(initialWebsites);
  const [news, setNews] = useState(initialNews);
  const [issues, setIssues] = useState(initialIssues);
  const [newsCard, setNewsCard] = useState(initialNewsCard);
  const [status, setStatus] = useState("");
  const [isUpdated, setUpdated] = useState(false);
  const [isLoaded, setLoaded] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [date, setDate] = useState(null);
  const [originalSignature, setOriginalSignature] = useState({
    data: {},
    isChanged: false,
  });
  const [isPhone, setIsPhone] = useState(false);
  const [isCountry, setIsCountry] = useState(false);
  const [countryCodeId, setCountryCodeId] = useState(initialCountryCodeId);

  const { preferred_username } = tokenParsed;

  const is = {
    me: preferred_username === user_id,
    departments: pathname.includes("departments"),
    users: pathname.includes("users") && !user_id,
    user: !!user_id && preferred_username !== user_id,
    websites: pathname.includes("websites"),
    news: pathname.includes("news"),
    services: pathname.includes("services"),
    mail: pathname.includes("mail"),
  };

  const myPage = `/staff/users/${preferred_username}`;

  const getRequest = async (end, method, data) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/${end}`;
    const options = { method, data, url, headers };

    return axios(options);
  };

  const getIssues = () => {
    getRequest("jira", "post", { user_id }).then((resp) => {
      setIssues({ data: resp.data.issues, loaded: !!resp.data.issues.length });
    });
  };

  const getInit = async () => {
    getRequest("init", "get").then(({ data }) => {
      setLoaded(data.loaded);
    });
  };

  const getMembers = () => {
    clearProgress();
    startProgress();

    getRequest("users", "post", { user_id }).then((resp) => {
      setMembers({
        data: resp.data,
        loaded: true,
      });

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const getUser = () => {
    getRequest("users", "post", { user_id }).then((resp) => {
      setStatus(resp.data[0].status);
      setUser({
        data: resp.data[0],
        loaded: true,
      });

      setFavorites({
        data: resp.data[0]["favorites"],
        loaded: !!resp.data[0]["favorites"].length,
      });
    });
  };

  const phoneFormater = (phoneNumber, codeLength) => {
    const cc = `(\\+?\\d{${codeLength ? codeLength : "1,4"}})`;

    return phoneNumber.replace(
      new RegExp(`${cc}?(\\d{3})(\\d{3})(\\d{4})`),
      (_, p0, p1, p2, p3) => `${p0} ${p1} ${p2} ${p3}`,
    );
  };

  const patchSignature = async () => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const attrArray = Object.entries(signature.data).map((m) => {
      const attribute = m[0];

      if (
        attribute === "alternative_phone" &&
        !!signature.data.alternative_phone
      ) {
        const cc = countryCodes[countryCodeId].code;
        const value = phoneFormater(
          `${cc}${signature.data.alternative_phone}`,
          cc.length - 1,
        );

        return [attribute, value];
      } else if (attribute === "telegram" && !!signature.data.telegram) {
        const value = `https://t.me/${signature.data.telegram.replace(/^@/, "")}`;
        return [attribute, value];
      } else return m;
    });

    const attrObject = Object.fromEntries(attrArray);

    const options = {
      method: "POST",
      url: `https://n8n.nekrasovka.ru/webhook/mailcow/set-attributes`,
      headers: {
        "X-Team-Token": TOKEN_KEY,
        "Content-Type": "application/json",
      },
      data: {
        attr: {
          attribute: Object.keys(attrObject),
          value: Object.values(attrObject),
        },
      },
    };

    try {
      await axios(options);
      setOriginalSignature({
        data: JSON.stringify(signature.data),
        isChanged: false,
      });
    } catch (error) {
      console.error("❗", error);
    }
  };

  const getSignature = async () => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const options = {
      method: "GET",
      url: `https://n8n.nekrasovka.ru/webhook/mailcow/mailbox`,
      headers: {
        "X-Team-Token": TOKEN_KEY,
        "Content-Type": "application/json",
      },
    };

    try {
      const resp = await axios(options);

      if (!!resp.data.alternative_phone) {
        const matched = resp.data.alternative_phone.match(/^(\S+)\s(.*)$/);
        const fPart = matched[1];
        const sPart = matched[2].replace(/\s/g, "");
        const sIndex = countryCodes.findIndex((item) => item.code === fPart);
        setCountryCodeId(sIndex);

        resp.data.alternative_phone = sPart;
      }

      if (resp.data.phone === "") {
        resp.data.phone = phones[0];
      }

      if (!!resp.data.telegram) {
        resp.data.telegram = resp.data.telegram.replace(
          /https:\/\/t\.me\//g,
          "",
        );
      }

      setSignature({
        data: resp.data,
        loaded: true,
      });

      const originalJSON = JSON.stringify(resp.data);
      setOriginalSignature({ isChanged: false, data: originalJSON });
    } catch (error) {
      console.error("❗", error);
    }
  };

  const handlePhone = (groupName, id) => {
    handleInputChange({ target: { name: groupName, value: phones[id] } });
    setIsPhone(false);
  };

  const handleCountry = (groupName, id) => {
    setCountryCodeId(id);
    setIsCountry(false);
  };

  const handleCopySignature = async () => {
    let {
      fullname,
      title,
      department,
      internal_phone,
      alternative_phone,
      alternative_email,
      telegram,
      phone,
    } = signature.data;
    let { name, email } = tokenParsed;

    title = title ? `<div>${title}</div>` : "";
    department = department ? `<div>${department}</div>` : "";
    alternative_phone = alternative_phone
      ? `<br><span class="phone-number">${phoneFormater(countryCodes[countryCodeId].code + alternative_phone, countryCodes[countryCodeId].code.length - 1)}</span`
      : "";
    fullname = fullname ? fullname : name;
    email = `<div><a class="email" href="mailto:${email}">${email}</a><br>`;
    alternative_email = alternative_email
      ? `<a class="email" href="mailto:${alternative_email}">${alternative_email}</a>`
      : "";
    phone = `<span class="phone-number">${phone ? phone : "+7 495 916 9068"}${internal_phone ? " #" + internal_phone : ""}</span>`;
    telegram = telegram
      ? `<br><div><a href="https://t.me/${telegram}">https://t.me/${telegram}</a></div>`
      : "";

    const signatureText = `<div>
<div>--</div>
<div>С уважением,<br>${fullname}</div>
${title}
${department}
${email}
${alternative_email}
${phone}
${alternative_phone}
${telegram}
<div><img style="height: 71px;width: 152px;" width="152" height="71" src="https://nekrasovka.ru/share/mail/nekrasovka.png" alt="icon"></a></div>
<div><a href="https://vk.com/nekrasovkalibrary"><img style="height: 32px;width: 32px;" width="32" height="32" src="https://nekrasovka.ru/share/mail/vk.png" alt="icon"></a>&nbsp;<a href="https://t.me/nekrasovkalibrary"><img style="height: 32px;width: 32px;" width="32" height="32" src="https://nekrasovka.ru/share/mail/tg.png" alt="icon"></a></div></div>`;

    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(signatureText);
        dispatch({
          type: "ALERT_ON",
          text: "Подпись успешно скопирована!",
          name: "warning",
        });
      } catch (err) {
        console.error("Ошибка при копировании подписи: ", err);
      }
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = signatureText;
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand("copy");
        dispatch({
          type: "ALERT_ON",
          text: "Подпись успешно скопирована!",
          name: "warning",
        });
      } catch (err) {
        console.error(
          "Ошибка при копировании подписи через execCommand: ",
          err,
        );
      }

      document.body.removeChild(textarea);
    }
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    let isError = false;

    if (name === "internal_phone") {
      const pattern = /^\d*$/;
      isError = !pattern.test(value) || value.length > 3;
    }

    if (name === "alternative_phone") {
      const pattern = /^\d*$/;
      isError = !pattern.test(value);
    }

    if (name === "telegram") {
      const pattern = /^[@a-zA-Z0-9_]*$/;
      isError = !pattern.test(value);
    }

    const data = {
      ...signature.data,
      [name]: isError ? signature.data[name] : value,
    };
    const isChanged = JSON.stringify(data) !== originalSignature.data;

    setOriginalSignature({ ...originalSignature, isChanged });
    setSignature({
      ...signature,
      data,
    });
  };

  const getServices = () => {
    clearProgress();
    startProgress();

    getRequest("services", "post", { user_id: preferred_username }).then(
      (resp) => {
        setServices({
          data: resp.data["services"],
          loaded: !!resp.data["services"].length,
        });

        finishProgress();
        setTimeout(() => closeProgress(), 300);
      },
    );
  };

  const patchSelf = (data) => {
    getRequest("users", "patch", data);
  };

  const patchServices = (id) => {
    getRequest("services", "patch", { id });
  };

  const patchFavorites = (id) => {
    getRequest("favorites", "patch", { id });
  };

  const getDepartments = () => {
    clearProgress();
    startProgress();

    getRequest("departments", "post", { department_id }).then((resp) => {
      setDepartments({ data: resp.data.departments, loaded: true });

      if (!!department_id) {
        setMembers({ data: resp.data.members, sorted: [], loaded: true });
      } else {
        if (members.loaded) setMembers(initialMembers);
      }

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const getNews = async (params) => {
    const resp = await getRequest("news", "post", { ...params, news_id });
    if (!!news_id) {
      setNewsCard({ data: resp.data, loaded: true });
      setNews(initialNews);
    } else {
      setNews({ data: resp.data, loaded: true });
      setNewsCard(initialNewsCard);
    }

    return resp.status;
  };

  const getWebsites = () => {
    clearProgress();

    getRequest("websites", "get").then((resp) => {
      startProgress();
      setWebsites({ data: resp.data, loaded: true });

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const handleDate = async (d) => {
    const newDate = !!date && d.getDate() === date.getDate() ? null : d;

    if (!!news_id) {
      history.replace(`/staff/news/`);
    }

    setNews(initialNews);

    if (!!newDate) {
      const created = format(d, "yyyy-MM-dd", { locale });
      await getNews({ created });
    } else await getNews();

    setDate(newDate);
  };

  const handleServices = (id) => {
    const newServices = [...services.data];
    const name = newServices.find((item) => item.id === id).name; // eslint-disable-line

    const filteredServices = newServices.filter((service) => service.id !== id);
    setServices({ loaded: !!filteredServices.length, data: filteredServices });

    patchServices(id);
    dispatch({
      type: "ALERT_ON",
      text: `Добавили сервис «${name}» в раздел «Профиль. Избранные сервисы»`,
      name: "warning",
    });
  };

  const handleFavorites = (id) => {
    const newFavorites = [...favorites.data];
    const name = favorites.data.find((item) => item.id === id).name; // eslint-disable-line

    const inFavorites = !!favorites.data.find((item) => item.id === id);

    if (inFavorites) {
      const filteredFavorites = newFavorites.filter((item) => item.id !== id);
      setFavorites({
        ...favorites,
        loaded: !!filteredFavorites.length,
        data: filteredFavorites,
      });
    } else {
      newFavorites.push(id);
      setFavorites({
        ...favorites,
        loaded: !!newFavorites.length,
        data: newFavorites,
      });
    }

    patchFavorites(id);
    dispatch({
      type: "ALERT_ON",
      text: `Перенесли сервис «${name}» в раздел «Сервисы»`,
      name: "warning",
    });
  };

  const handleChange = ({ target }) => {
    setStatus(target.value);
    setUpdated(true);
  };

  const handleSave = () => {
    patchSelf({ ...user, status });
    setUpdated(false);
  };

  const handleCancel = () => {
    setStatus(user.status);
    setUpdated(false);
  };

  const handleSearch = ({ target }) => {
    const inputValue = target.value;

    setQuery(inputValue);

    if (inputValue && inputValue.length > 2) {
      getRequest("search", "post", { query: inputValue }).then((resp) => {
        const isResults =
          resp.data.departments.length ||
          resp.data.members.length ||
          resp.data.websites.length ||
          resp.data.services.length;

        if (isResults) {
          if (!isModal) setIsModal(true);
          setSearch(resp.data);
        }
      });
    } else {
      if (isModal) setIsModal(false);
      setSearch(initialSearch);
    }
  };

  const resetSearch = () => {
    query ? setQuery("") : null;
    setSearch(initialSearch);
    setIsModal(false);
  };

  const dragStart = (e) => {
    draggedItemIndex.current = e.target.id;
  };

  const dragEnter = (e) => {
    draggedOverItemIndex.current = e.currentTarget.id;
  };

  const dragEnd = () => {
    const newData = [...services.data];

    const draggedItemContent = newData[draggedItemIndex.current];
    newData.splice(draggedItemIndex.current, 1);
    newData.splice(draggedOverItemIndex.current, 0, draggedItemContent);

    draggedItemIndex.current = null;
    draggedOverItemIndex.current = null;

    const idArray = newData.map((item) => item.id);
    const serializedIds = JSON.stringify(idArray);

    const newSortedData = mapOrder(newData, idArray, "id");

    setServices({ ...services, data: newSortedData });
    patchSelf({ service_sort_id: serializedIds });
  };

  const dragOver = (e) => e.preventDefault();

  useEffect(() => {
    getInit();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta name="theme-color" content="#edeee9" />
      </Helmet>
      <Container>
        <Navigation
          is={is}
          myPage={myPage}
          resetSearch={resetSearch}
          isModal={isModal}
          query={query}
          search={search}
          handleSearch={handleSearch}
          setIsModal={setIsModal}
          handleDate={handleDate}
          today={today}
          date={date}
        />
        {isLoaded && (
          <Fragment>
            {is.me && (
              <User
                user_id={user_id}
                user={user}
                favorites={favorites}
                status={status}
                initialUser={initialUser}
                isDisabled={false}
                handleCancel={handleCancel}
                handleSave={handleSave}
                handleChange={handleChange}
                isUpdated={isUpdated}
                getUser={getUser}
                setUser={setUser}
                setFavorites={setFavorites}
                initialFavorites={initialFavorites}
                handleFavorites={handleFavorites}
                getNews={getNews}
                setNews={setNews}
                initialNews={initialNews}
                news={news}
                history={history}
                issues={issues}
                getIssues={getIssues}
                setIssues={setIssues}
                initialIssues={initialIssues}
              />
            )}
            {is.departments && (
              <Departments
                departments={departments}
                department_id={department_id}
                history={history}
                members={members}
                initialDepartments={initialDepartments}
                setDepartments={setDepartments}
                getDepartments={getDepartments}
                setMembers={setMembers}
                initialMembers={initialMembers}
              />
            )}
            {is.users && (
              <Users
                members={members}
                history={history}
                initialMembers={initialMembers}
                getMembers={getMembers}
                setMembers={setMembers}
              />
            )}
            {is.user && (
              <User
                user_id={user_id}
                user={user}
                favorites={favorites}
                status={status}
                initialUser={initialUser}
                isDisabled={true}
                handleCancel={null}
                handleSave={null}
                handleChange={null}
                isUpdated={false}
                getUser={getUser}
                setUser={setUser}
                setFavorites={setFavorites}
                initialFavorites={initialFavorites}
                issues={issues}
                getIssues={getIssues}
                setIssues={setIssues}
                initialIssues={initialIssues}
              />
            )}
            {is.websites && (
              <Websites
                getWebsites={getWebsites}
                setWebsites={setWebsites}
                websites={websites}
                initialWebsites={initialWebsites}
              />
            )}
            {is.news && (
              <News
                getNews={getNews}
                setNews={setNews}
                setNewsCard={setNewsCard}
                initialNews={initialNews}
                initialNewsCard={initialNewsCard}
                news={news}
                newsCard={newsCard}
                handleDate={handleDate}
                today={today}
                date={date}
                history={history}
                news_id={news_id}
                preferred_username={preferred_username}
              />
            )}
            {is.services && (
              <Services
                services={services}
                initialServices={initialServices}
                setServices={setServices}
                getServices={getServices}
                dragOver={dragOver}
                dragEnd={dragEnd}
                dragEnter={dragEnter}
                dragStart={dragStart}
                handleServices={handleServices}
                isDisabled={false}
              />
            )}
            {is.mail && (
              <Mail
                patchSignature={patchSignature}
                signature={signature}
                initialSignature={initialSignature}
                setSignature={setSignature}
                getSignature={getSignature}
                tokenParsed={tokenParsed}
                phoneFormater={phoneFormater}
                originalSignature={originalSignature}
                setOriginalSignature={setOriginalSignature}
                countryCodes={countryCodes}
                phones={phones}
                handlePhone={handlePhone}
                handleCountry={handleCountry}
                handleInputChange={handleInputChange}
                countryCodeId={countryCodeId}
                setCountryCodeId={setCountryCodeId}
                initialCountryCodeId={initialCountryCodeId}
                isPhone={isPhone}
                setIsPhone={setIsPhone}
                isCountry={isCountry}
                setIsCountry={setIsCountry}
                handleCopySignature={handleCopySignature}
              />
            )}
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({
  allowance: { tokenParsed, checkTokenExpiredYesUpdate },
}) => ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(Staff);
