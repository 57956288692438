import React, { useEffect } from "react";
import Card from "../../components/Card";
import Applications from "../../components/Applications";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NewsCard from "../../components/NewsCard";
import { connect } from "react-redux";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../../../reducers/progress/actions";
import LinkType from "../../../../nekrasovka-ui/Link";
import IssueCard from "../../components/IssueCard";

const User = ({
  user_id,
  user,
  favorites,
  status,
  isUpdated,
  handleCancel,
  handleSave,
  handleChange,
  getUser,
  setUser,
  initialUser,
  isDisabled,
  setFavorites,
  initialFavorites,
  handleFavorites,
  getNews,
  setNews,
  initialNews,
  news,
  history,
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
  issues,
  setIssues,
  initialIssues,
  getIssues,
}) => {
  const handleNewsCard = (id) => {
    history.replace(`/staff/news/${id}`);
  };

  const getData = () => {
    clearProgress();
    startProgress();

    getUser();
    getIssues();
    if (!isDisabled) {
      getNews({ limit: 3 });
    }

    finishProgress();
    setTimeout(() => closeProgress(), 300);
  };

  const resetData = () => {
    if (!isDisabled) {
      setNews(initialNews);
    }

    setUser(initialUser);
    setFavorites(initialFavorites);
    setIssues(initialIssues);
  };

  useEffect(() => {
    getData();

    return () => resetData();
  }, []);

  return (
    <Container>
      <Card
        user_id={user_id}
        state={user.data}
        status={status}
        isDisabled={isDisabled}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        isUpdated={isUpdated}
      />
      <Right>
        <RightBlock>
          <PageContainer>
            <LinkType
              to={initialFavorites.default.url}
              isExternal={initialFavorites.default.is_external}
            >
              <Page>
                <h3>{initialFavorites.default.name}</h3>
              </Page>
            </LinkType>
            <span>{initialFavorites.default.description}</span>
          </PageContainer>
          <TitleContainer>
            <TableTitle>Избранные сервисы</TableTitle>
            {!isDisabled && (
              <TitleButton as={Link} to={`/staff/services`}>
                Все сервисы
              </TitleButton>
            )}
          </TitleContainer>
          {favorites.loaded ? (
            <FavoritesApplications
              pagesLength={Math.round(favorites.data.length / 2)}
              pages={favorites.data}
              dragOver={null}
              dragEnd={null}
              dragEnter={null}
              dragStart={null}
              isDescription={false}
              isFavorites={false}
              isDisabled={isDisabled}
              handleFavorites={handleFavorites}
            />
          ) : (
            <NoDataContainer as={Link} to="/staff/services">
              Здесь будут отображаться избранные сервисы.
              {!isDisabled && " Добавить?"}
            </NoDataContainer>
          )}
        </RightBlock>
        {!isDisabled && (
          <RightBlock>
            <TitleContainer>
              <TableTitle>Последние новости</TableTitle>
              <TitleButton disabled as={Link} to={`/staff/news`}>
                Все новости
              </TitleButton>
            </TitleContainer>
            {news.loaded ? (
              <NewsCardsContainer newsLength={news.data.length}>
                {news.data.map((item) => {
                  return (
                    <NewsCard
                      card={item}
                      key={item.id}
                      handleNewsCard={handleNewsCard}
                      isExtended={false}
                    />
                  );
                })}
              </NewsCardsContainer>
            ) : (
              <NoDataContainer>
                Здесь будут отображаться важные новости.
              </NoDataContainer>
            )}
          </RightBlock>
        )}
        <RightBlock>
          <TitleContainer>
            <TableTitle>Приоритетные задачи</TableTitle>
            <TitleButton
              as="a"
              href={`https://team.nekrasovka.ru/jira/browse/INTRANET-216?filter=-1&jql=status%20%3D%20Open%20AND%20assignee%20in%20(${user_id})%20order%20by%20updated%20DESC`}
              target="_blank"
              rel="noopener noreferrer"
              disabled
            >
              Все задачи
            </TitleButton>
          </TitleContainer>
          {issues.loaded ? (
            <IssuesCarsContainer issuesLength={issues.data.length}>
              {issues.data.map((item) => {
                return <IssueCard issue={item} key={item.id} />;
              })}
            </IssuesCarsContainer>
          ) : (
            <NoDataContainer>
              Здесь будут отображаться приоритетные задачи.
            </NoDataContainer>
          )}
        </RightBlock>
      </Right>
    </Container>
  );
};

export default connect(null, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(User);

const Container = styled.div`
  @media (min-width: 701px) {
    display: grid;
    grid-template-columns: 436px 1fr;
    column-gap: 15px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
`;

const FavoritesApplications = styled(Applications)`
  width: 100%;

  > div {
    box-shadow: rgba(0, 0, 0, 0.12) 0 0 2px 0;
  }

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    text-align: center;
    font-size: 14px;
  }

  @media (min-width: 701px) {
    gap: 15px;
    grid-template-columns: repeat(auto-fill, 150px);
    margin-right: 30px;
  }

  @media (max-width: 700px) {
    gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    grid-template-columns: repeat(${({ pagesLength }) => pagesLength}, 150px);
    padding: 0 15px;

    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
      display: none;
    }

    > div {
      > div:last-child {
        display: none;
      }
    }
  }
`;

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 430px;

  span {
    color: #777777ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }

  @media (min-width: 701px) {
    column-gap: 15px;
  }

  @media (max-width: 700px) {
    padding: 0 15px;
    column-gap: 10px;
  }
`;

const Page = styled.div`
  display: flex;
  column-gap: 10px;
  width: 150px;
  height: 67px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #dddbcfff;

  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
  }

  @media (hover: hover) {
    cursor: pointer;
    border: 1px solid #dddbcfff;
    transition: background-color 0.13s ease-in-out;

    &:hover {
      background-color: #edeee9;
      border: 1px solid #c4c4c4;
    }
  }

  @media (min-width: 701px) {
    padding: 20px 15px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 5px;
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 500;

  @media (min-width: 701px) {
    width: 100%;
    margin-right: 30px;
  }

  @media (max-width: 700px) {
    text-align: center;
    margin: 0 15px;
    line-height: 22px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 701px) {
    row-gap: 30px;
    padding-right: 30px;
  }

  @media (max-width: 700px) {
    row-gap: 20px;
  }
`;

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 701px) {
    row-gap: 30px;
  }

  @media (max-width: 700px) {
    row-gap: 20px;
  }
`;

const NewsCardsContainer = styled.div`
  display: grid;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }

  > div > div {
    > h3 {
      font-size: 14px !important;
    }
  }

  @media (min-width: 701px) {
    grid-template-columns: repeat(3, minmax(315px, 1fr));
    column-gap: 15px;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(${({ newsLength }) => newsLength}, 310px);
    padding: 0 15px;
    column-gap: 10px;

    > div {
      border-radius: 5px;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;

const TitleButton = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  @media (min-width: 701px) {
    font-size: 14px;
  }

  @media (max-width: 700px) {
    font-size: 11px;
  }

  @media (hover: hover) {
    :hover {
      background-color: rgb(221, 219, 207);
    }
  }
`;

const TableTitle = styled.div`
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
`;

const IssuesCarsContainer = styled.div`
  display: grid;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 701px) {
    grid-template-columns: repeat(3, minmax(315px, 1fr));
    column-gap: 15px;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(${({ issuesLength }) => issuesLength}, 310px);
    padding: 0 15px;
    column-gap: 10px;
  }
`;
