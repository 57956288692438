import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon from "../../../../nekrasovka-ui/Icon";
import Search from "../Search";
import Calendar from "react-calendar";

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 701px) {
    background: rgb(221, 219, 207);
  }

  @media (max-width: 700px) {
    background-color: #edeee9;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px 15px;
    position: fixed;
    bottom: 0;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.3);
    z-index: 100;
  }
`;

const NavigationLink = styled(Link)`
  cursor: pointer;
  color: #222222;
  padding-bottom: 10px;
  border-bottom: 5px solid;
  position: relative;
  border-bottom-color: #ffad20;

  @media (min-width: 701px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }

  ${({ isActive }) =>
    isActive
      ? "border-bottom-color: #ffad20;"
      : `
  @media (min-width: 701px) {
    border-bottom-color: #dddbcf;
  }

  @media (max-width: 700px) {
    border-bottom-color: #edeee9;
  }
  
  @media (hover: hover) {
    &:hover {
      border-bottom-color: rgba(255, 173, 32, 0.46);
    }
  }
  `};
`;

const NavigationIcons = styled.div`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    display: flex;
    width: 100%;
    justify-content: right;
    column-gap: 20px;
  }
`;

const NavigationRoutes = styled.div`
  border-bottom: 1px solid #c4c4c4;

  @media (min-width: 701px) {
    max-width: 100%;
    display: flex;
    column-gap: 15px;
    background: rgb(221, 219, 207);
    margin: 20px 30px;
  }

  @media (max-width: 700px) {
    width: 100%;
    display: ${({ isNavigation }) => (isNavigation ? "flex" : "none")};
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const NavigationSearch = styled(Search)`
  @media (min-width: 701px) {
    display: flex;
    padding-bottom: 20px;
  }

  @media (max-width: 700px) {
    display: ${({ isSearch }) => (isSearch ? "flex" : "none")};
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
  }
`;

const NavigationCalendar = styled.div`
  display: ${({ isCalendar }) => (isCalendar ? "flex" : "none")};
  margin-bottom: 10px;

  .react-calendar {
    display: block;
    width: 100%;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #edeee9 !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    .react-calendar {
      border-radius: 5px;
    }
  }
`;

const Navigation = ({
  resetSearch,
  myPage,
  isModal,
  query,
  search,
  handleSearch,
  setIsModal,
  handleDate,
  today,
  date,
  is,
}) => {
  const [isNavigation, setIsNavigation] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);

  const handleClick = () => {
    resetSearch();
  };

  const handleSearchClick = () => {
    setIsSearch(!isSearch);
    if (isSearch) resetSearch();
    if (isNavigation) setIsNavigation(false);
    if (isCalendar) setIsCalendar(false);
  };

  const handleNavigationClick = () => {
    setIsNavigation(!isNavigation);
    if (isCalendar) setIsCalendar(false);
    if (isSearch) {
      setIsSearch(false);
      resetSearch();
    }
  };

  const handleCalendarClick = () => {
    setIsCalendar(!isCalendar);
    if (isNavigation) setIsNavigation(false);
    if (isSearch) {
      setIsSearch(false);
      resetSearch();
    }
  };

  return (
    <Container>
      <NavigationRoutes isNavigation={isNavigation}>
        {[
          { name: "Профиль", route: myPage, active: is.me },
          {
            name: "Почта",
            route: "/staff/mail",
            active: is.mail,
          },
          {
            name: "Новости",
            route: "/staff/news",
            active: is.news,
          },
          {
            name: "Сервисы",
            route: "/staff/services",
            active: is.services,
          },
          {
            name: "Сайты",
            route: "/staff/websites",
            active: is.websites,
          },
          {
            name: "Отделы",
            route: "/staff/departments",
            active: is.departments,
          },
          {
            name: "Коллеги",
            route: "/staff/users",
            active: is.users || is.user,
          },
        ].map((item, i) => {
          return (
            <NavigationLink
              key={i}
              to={item.route}
              isActive={item.active}
              onClick={handleClick}
            >
              {item.name}
            </NavigationLink>
          );
        })}
      </NavigationRoutes>
      <NavigationSearch
        isSearch={isSearch}
        isModal={isModal}
        query={query}
        search={search}
        handleSearch={handleSearch}
        setIsModal={setIsModal}
      />
      <NavigationCalendar isCalendar={isCalendar}>
        <Calendar
          selectRange={false}
          onChange={handleDate}
          value={date}
          maxDate={today}
          locale="ru-RU"
        />
      </NavigationCalendar>
      <NavigationIcons>
        {is.news && (
          <Icon
            icon="calendarPlaner"
            fill={isCalendar ? "#222222" : "#777777"}
            height={20}
            width={27}
            onClick={handleCalendarClick}
          />
        )}
        <Icon
          icon="search"
          fill={isSearch ? "#222222" : "#777777"}
          height={20}
          width={27}
          onClick={handleSearchClick}
        />
        <Icon
          icon="menu"
          fill={isNavigation ? "#222222" : "#777777"}
          height={20}
          width={27}
          onClick={handleNavigationClick}
        />
      </NavigationIcons>
    </Container>
  );
};

export default Navigation;
