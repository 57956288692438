import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Librarian from "./containers/Librarian";
import Staff from "./containers/Staff";
import Digitization from "./containers/Digitization";
import BooksNotFound from "./containers/BooksNotFound";
import Administrator from "./containers/Administrator";
import AdministratorPage from "./containers/Administrator/components/AdministratorPage";
import Dedusting from "./containers/Dedusting";
import Bookcase from "./containers/Dedusting/components/Bookcase";
import Creation from "./containers/Dedusting/components/Creation";
import Statistics from "./containers/Dedusting/components/Statistics";
import User from "./containers/Dedusting/components/User";
import AdminRoute from "./containers/AdminRoute";
import ReaderTicketGenerator from "./containers/ReaderTicketGenerator";

export default ({ preferred_username }) => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => <Redirect to={`/staff/users/${preferred_username}`} />}
    />
    <Route path="/tool/cards/" component={ReaderTicketGenerator} />
    <Route path="/librarian" component={Librarian} />
    <Route exact path="/staff" component={Staff} />
    <Route exact path="/staff/users" component={Staff} />
    <Route path="/staff/users/:user_id" component={Staff} />
    <Route exact path="/staff/departments" component={Staff} />
    <Route path="/staff/departments/:department_id" component={Staff} />
    <Route path="/staff/websites" component={Staff} />
    <Route exact path="/staff/news" component={Staff} />
    <Route path="/staff/news/:news_id" component={Staff} />
    <Route path="/staff/services" component={Staff} />
    <Route path="/staff/mail" component={Staff} />
    <Route path="/backoffice/digitization" component={Digitization} />
    <Route path="/backoffice/booksnotfound" component={BooksNotFound} />
    <AdminRoute path="/backoffice/dedusting" exact>
      <Dedusting />
    </AdminRoute>
    <AdminRoute path="/backoffice/dedusting/bookcase/create">
      <Creation />
    </AdminRoute>
    <AdminRoute path="/backoffice/dedusting/statistics">
      <Statistics />
    </AdminRoute>
    <Route path="/backoffice/dedusting/users/:user_id" component={User} />
    <Route
      path="/backoffice/dedusting/bookcases/:bookcase_id"
      component={Bookcase}
    />
    <Route exact path="/admin" component={Administrator} />
    <Route
      path={["/admin/services", "/admin/websites"]}
      component={AdministratorPage}
    />
  </Switch>
);
