import React, { useEffect } from "react";
import styled from "styled-components";
import Signature from "../../../Main/components/Signature";
import { connect } from "react-redux";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../../../reducers/progress/actions";
import Form from "../../../Main/components/Form";
import Button from "../../../../nekrasovka-ui/Button";

const Mail = ({
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
  signature,
  setSignature,
  initialSignature,
  getSignature,
  tokenParsed,
  patchSignature,
  phoneFormater,
  originalSignature,
  countryCodes,
  phones,
  handlePhone,
  handleCountry,
  handleInputChange,
  countryCodeId,
  isPhone,
  setIsPhone,
  isCountry,
  setIsCountry,
  handleCopySignature,
  setCountryCodeId,
  initialCountryCodeId,
}) => {
  const getMail = () => {
    clearProgress();
    startProgress();

    getSignature();

    finishProgress();
    setTimeout(() => closeProgress(), 300);
  };

  const resetData = () => {
    setSignature(initialSignature);
    setCountryCodeId(initialCountryCodeId);
  };

  useEffect(() => {
    getMail();

    return () => resetData();
  }, []);

  return (
    <Container>
      <MailTitle>Почта</MailTitle>
      {signature.loaded && (
        <SignatureForm>
          <div>
            <Button
              onClick={() => window.open("https://mail2.nekrasovka.ru/")}
              name="Перейти в почту"
            />
            <SignatureDescription>
              Корпоративная электронная почта Некрасовки, которая используется
              для внутренней переписки и общения с пользователями
            </SignatureDescription>
          </div>
          <div>
            <SignatureTitle>Подпись в письмах</SignatureTitle>
            <div>
              <Form
                signature={signature.data}
                handleInputChange={handleInputChange}
                from_name={tokenParsed.name}
                isPhone={isPhone}
                setIsPhone={setIsPhone}
                isCountry={isCountry}
                setIsCountry={setIsCountry}
                phones={phones}
                countryCodes={countryCodes}
                countryCodeId={countryCodeId}
                handlePhone={handlePhone}
                handleCountry={handleCountry}
              />
              <Signature
                signature={signature.data}
                from_name={tokenParsed.name}
                from_addr={tokenParsed.email}
                patchSignature={patchSignature}
                getMail={getMail}
                phoneFormater={phoneFormater}
                isChanged={originalSignature.isChanged}
                alternativePhoneCountryCode={countryCodes[countryCodeId].code}
                handleCopySignature={handleCopySignature}
              />
            </div>
          </div>
        </SignatureForm>
      )}
    </Container>
  );
};

export default connect(null, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(Mail);

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 701px) {
    padding: 0 30px;
  }
`;

const MailTitle = styled.div`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    margin-left: 15px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
    padding-top: 20px;
  }
`;

const SignatureForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: 701px) {
    > div {
      :nth-child(1) {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      :nth-child(2) {
        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    padding: 0 15px;

    > div {
      :nth-child(1) {
        display: flex;
        column-gap: 10px;

        > button {
          order: 2;
        }

        > span {
          order: 1;
        }
      }

      :nth-child(2) {
        > div {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }
      }
    }
  }
`;

const SignatureTitle = styled.div`
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const SignatureDescription = styled.span`
  font-size: 14px;
  color: rgb(119, 119, 119);
`;
