import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../reducers/progress/actions";
import { connect } from "react-redux";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import { useHistory, useLocation } from "react-router";
import qs from "qs";
import { Link } from "react-router-dom";

const Digitization = ({
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { barcode } = qs.parse(search, { ignoreQueryPrefix: true });

  const componentRef = useRef();
  const [state, setState] = useState({ loaded: false, data: {} });
  const [db, setDb] = useState("56");
  const [selectedTag, setSelectedTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [isPrinters, setIsPrinters] = useState(false);

  const printers = {
    "10.10.1.254": "Принтер в Краеведении",
    "10.10.1.251": "Принтер в ОБФ",
    "10.10.1.27": "Принтер в ОБИО",
    "10.10.1.242": "Принтер в ОИТ 1",
    "10.10.1.31": "Принтер в ОИТ 2",
    "192.168.10.251": "Принтер ОКиНОД 1",
    "192.168.10.250": "Принтер ОКиНОД 2",
  };

  const endings = {
    января: "январь",
    февраля: "февраль",
    марта: "март",
    апреля: "апрель",
    мая: "май",
    июня: "июнь",
    июля: "июль",
    августа: "август",
    сентября: "сентябрь",
    октября: "октябрь",
    ноября: "ноябрь",
    декабря: "декабрь",
  };

  const initializeState = () => setState({ loaded: false, data: {} });

  const findKeyAndReplace = (obj) => {
    const newObj = {};
    const keys = Object.keys(endings);

    Object.keys(obj).forEach((oldKey) => {
      const reg = oldKey.replace(/[^а-яА-Я]/g, "");
      const newKey = keys.find((endKey) => endKey.includes(reg));
      newObj[newKey] = obj[oldKey];
    });
    return newObj;
  };

  const constructRequestOptions = (url, data) => ({
    method: "post",
    url,
    data,
  });

  const fetchBarcodeData = async (data) => {
    try {
      setLoading(true);
      clearProgress();
      startProgress();

      if (state.loaded) initializeState();

      const response = await axios(
        constructRequestOptions(`${process.env.REACT_APP_API}/barcode`, data),
      );

      const { data: responseData } = response;

      if (responseData) {
        setState({
          loaded: true,
          data: {
            magazines: findKeyAndReplace(responseData.magazines),
            name: responseData.name,
            year: responseData.year,
          },
        });
      } else {
        initializeState();
      }

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScan = async (e) => {
    history.replace(`?barcode=${e.detail.scanData}`);
    await fetchBarcodeData({ barcode: e.detail.scanData, db });
  };

  const postPrint = async (data) => {
    const url = `${process.env.REACT_APP_API}/print`;
    await axios(constructRequestOptions(url, data));
  };

  const createPrintObject = (item) => {
    const isDay = !!item.day;
    const match = item.number.split(" ");
    const num1 = `№ ${match[1]}`;
    const num2 = match.length > 2 ? `${match[2]}` : "";
    const date = `${isDay ? item.day + "." : ""}${findIndexByKey(endings)}`;
    const info = `${isDay ? item.day + " " : ""}${item.month}, ${item.number}`;
    return { barcode, num1, num2, date, info };
  };

  const handlePrint = async (item) => {
    if (selectedPrinter) {
      const printData = [createPrintObject(item)];
      await postPrint({ data: printData, selectedPrinter });
    } else {
      alert("Выберите принтер");
    }
  };

  const handlePrintAll = async () => {
    if (selectedPrinter) {
      if (confirm("Достаточно ленты для печати?")) {
        const printData =
          state.data.magazines[selectedTag].map(createPrintObject);
        await postPrint({ data: printData, selectedPrinter });
      }
    } else {
      alert("Выберите принтер");
    }
  };

  const findIndexByKey = (obj) => {
    const keys = !!obj[selectedTag] ? Object.keys(obj) : Object.values(obj);
    const index = keys.indexOf(selectedTag) + 1;
    return index > 9 ? index : `0${index}`;
  };

  const handlePrinter = (name, id) => {
    setSelectedPrinter(id);
    setIsPrinters(!isPrinters);
    document.cookie = `printer=${id}`;
  };

  const handleBarcodeChange = ({ target }) => {
    history.replace(
      !!target.value ? `?barcode=${target.value}` : location.pathname,
    );
  };

  const focusOnBarcode = ({ target }) => target.focus();

  useEffect(() => {
    document.body.addEventListener("scan", handleScan);
    return () => document.body.removeEventListener("scan", handleScan);
  }, []);

  useEffect(() => {
    const printerMatch = document.cookie.match(/printer=([^;]*)/);
    if (printerMatch) setSelectedPrinter(printerMatch[1]);
  }, []);

  return (
    <Container>
      <Navigation>
        <div>
          <BookcaseButton as={Link} to="/">
            Интранет
          </BookcaseButton>
        </div>
        <div>Оцифровка</div>
      </Navigation>
      <ContainerHeader isBarcode={!!barcode}>
        <div>
          <div>
            <label>Баркод</label>
            <input
              autoFocus
              size={13}
              type="text"
              name="barcode"
              value={barcode}
              onChange={handleBarcodeChange}
              onBlur={focusOnBarcode}
            />
          </div>
          <div>
            <label>БД</label>
            <input
              disabled
              size={2}
              type="text"
              value={db}
              onChange={(e) => setDb(e.target.value)}
            />
          </div>
          <Button
            name="Получить данные"
            onClick={() => fetchBarcodeData({ barcode, db })}
          />
        </div>
        <PrintersContainer>
          <label>Принтер</label>
          <VisualAction isPrinter={!!printers[selectedPrinter]}>
            <VisualTypeContainer isDisabled={false}>
              <input
                readOnly
                type="text"
                value={printers[selectedPrinter] || "Выберите принтер"}
                size={
                  !!printers[selectedPrinter]
                    ? printers[selectedPrinter].length + 3
                    : ""
                }
                onClick={() => setIsPrinters(!isPrinters)}
              />
              <PrintersModal
                isContentModal={isPrinters}
                setIsContentModal={setIsPrinters}
              >
                <PrintersButtons
                  groupName={"printers"}
                  activeButton={selectedPrinter}
                  buttons={printers}
                  handleButton={handlePrinter}
                />
              </PrintersModal>
            </VisualTypeContainer>
          </VisualAction>
        </PrintersContainer>
      </ContainerHeader>
      {loading && <Loading>Получение данных...</Loading>}
      {state.loaded && (
        <ContainerBody>
          {!!Object.keys(state.data).length ? (
            <Fragment>
              <TitleContainer>
                <span>{state.data.name}</span>
                <span>, </span>
                <span>{state.data.year} год</span>
              </TitleContainer>
              <TagsContainer>
                {Object.keys(endings).map((key) => {
                  let disabled = true;
                  let tagName = null;

                  if (!!state.data.magazines[key]) {
                    tagName = key;
                    disabled = false;
                  }

                  return (
                    <TagButton
                      key={key}
                      isActive={selectedTag === tagName}
                      disabled={disabled}
                      onClick={() => setSelectedTag(tagName)}
                    >
                      {endings[key]}
                    </TagButton>
                  );
                })}
              </TagsContainer>
              {!!selectedTag && (
                <ToPrintAllButton
                  name="Распечатать весь месяц"
                  onClick={handlePrintAll}
                />
              )}
              {!!Object.keys(state.data.magazines).length ? (
                <ToPrintButtonsContainer ref={componentRef}>
                  {!!selectedTag ? (
                    state.data.magazines[selectedTag].map((item, index) => {
                      return (
                        <ToPrintButton
                          key={index}
                          id={`${index}`}
                          onClick={() => handlePrint(item)}
                        >
                          <div>
                            <span>
                              {!!item.day ? item.day + "." : ""}
                              {findIndexByKey(endings)}
                            </span>
                            <span>{item.number}</span>
                          </div>
                          <img src={item.image} alt="barcode" />
                        </ToPrintButton>
                      );
                    })
                  ) : (
                    <span>Выберите&nbsp;месяц</span>
                  )}
                </ToPrintButtonsContainer>
              ) : (
                <span>Нет данных о номерах</span>
              )}
            </Fragment>
          ) : (
            <span>Нет данных по этому баркоду</span>
          )}
        </ContainerBody>
      )}
    </Container>
  );
};

export default connect(null, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(Digitization);

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 701px) {
    row-gap: 45px;
    padding: 0 30px;
  }

  @media (max-width: 700px) {
    row-gap: 30px;
  }
`;
const Navigation = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > div {
    :first-child {
      display: flex;
    }

    :last-child {
      font-size: 13px;
    }
  }

  @media (min-width: 769px) {
    padding: 15px 0 0;
  }

  @media (max-width: 768px) {
    padding: 15px 15px 0 15px;
  }
`;

const BookcaseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: initial;
  color: #222222;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  white-space: nowrap;
`;

const ContainerHeader = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    font-size: 16px;
  }

  input {
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid rgb(196, 196, 196);
    height: 33px;
  }

  button {
    border-radius: 5px;
  }

  > div {
    :nth-child(1) {
      display: flex;

      button {
        ${({ isBarcode }) =>
          isBarcode &&
          "background-color: rgb(64, 103, 126) !important; color: rgb(237, 238, 233) !important;"};
      }

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: 701px) {
    gap: 20px;

    input {
      text-align: center;
    }

    > div {
      :nth-child(1) {
        column-gap: 20px;

        > div {
          column-gap: 10px;

          :nth-child(1) > input {
            min-width: 131px;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    row-gap: 20px;

    label {
      min-width: 77px;
    }

    input {
      width: calc(100% - 77px);
    }

    > div {
      :nth-child(1) {
        padding: 0 15px;
        flex-direction: column;
        row-gap: 20px;

        > div {
          column-gap: 15px;
        }
      }
    }

    button {
      align-self: end;
    }
  }
`;

const ToPrintAllButton = styled(Button)`
  border-radius: 5px;

  :disabled {
    opacity: 0.3;
  }

  @media (max-width: 700px) {
    margin-left: 15px;
  }
`;

const PrintersContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;

  > div {
    display: flex;
    column-gap: 10px;
  }

  label {
    display: flex;
    align-items: center;
  }

  button {
    display: flex;
    align-items: center;
    height: 33px;
    padding: 5px 15px;
  }

  label {
    flex-direction: column;
  }

  @media (max-width: 700px) {
    padding: 0 15px;

    > div {
      width: 100%;
    }
  }
`;

const TagsContainer = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: 700px) {
    padding: 0 15px;
    overflow-x: scroll;
    scroll-behavior: smooth;

    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Loading = styled.p`
  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const ToPrintButton = styled.div`
  display: flex;
  border-radius: 5px;
  background-color: #ffff;
  border: 1px solid #ffffff;
  justify-content: space-between;
  height: 80px;

  img {
    display: flex;
    align-self: end;
    border-radius: 5px;
    object-fit: contain;
    height: 100%;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 5px 0 5px 5px;

    span {
      font-size: 11px;
      font-weight: 700;
      text-align: center;
    }
  }

  :active {
    background-color: initial;
    border: 1px solid rgb(196, 196, 196);

    > div {
      opacity: 0.3;
    }

    img {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      opacity: 0.3;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    :hover {
      border: 1px solid rgb(196, 196, 196);
    }
  }
`;

const ToPrintButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;

const TitleContainer = styled.div`
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;

const TagButton = styled.button`
  padding: 5px 10px;
  border: 1px solid rgb(196, 196, 196);

  :not(:disabled) {
    @media (hover: hover) {
      :hover {
        cursor: pointer;
        background-color: rgb(221, 219, 207);
      }
    }
  }

  ${({ isActive }) =>
    isActive ? "background-color: rgb(221, 219, 207);" : ""};
`;

const VisualAction = styled.div`
  input {
    padding: 10px;
    outline-style: none;
    margin: 0;
    background-color: initial;
    ${({ isPrinter }) => !isPrinter && "border-color: #a36257;"}
  }
`;

const VisualTypeContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 1;

  input {
    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
    width: 100%;
    min-width: 183px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const PrintersModal = styled(ContentModal)`
  top: 40px;
  left: 0;
  width: 100%;

  > div {
    padding: 0;

    > div {
      border: none;
      width: 100%;
    }

    ::after {
      right: 0;
      left: 15px;
    }
  }
`;

const PrintersButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
    white-space: nowrap;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;
