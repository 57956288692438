import React from "react";
import styled from "styled-components";
import Button from "../../../../nekrasovka-ui/Button";

const Signature = ({
  signature,
  from_name,
  from_addr,
  patchSignature,
  getMail,
  phoneFormater,
  isChanged,
  alternativePhoneCountryCode,
  handleCopySignature,
}) => {
  return (
    <Container>
      <SignatureBody>
        <div>--</div>
        <div>С уважением,</div>
        <div>{signature.fullname ? signature.fullname : from_name}</div>
        {!!signature.title && <div>{signature.title}</div>}
        {!!signature.department && <div>{signature.department}</div>}
        <div>{from_addr}</div>
        {!!signature.alternative_email && (
          <div>{signature.alternative_email}</div>
        )}
        <div>
          {signature.phone}{" "}
          {!!signature.internal_phone && `#${signature.internal_phone}`}
        </div>
        {!!signature.alternative_phone && (
          <div>
            {phoneFormater(
              alternativePhoneCountryCode + signature.alternative_phone,
              alternativePhoneCountryCode.length - 1,
            )}
          </div>
        )}
        {!!signature.telegram && (
          <div>https://t.me/{signature.telegram.replace(/^@/g, "")}</div>
        )}
        <div>
          <img
            style={{ height: "71px", width: "152px" }}
            src="https://nekrasovka.ru/share/mail/nekrasovka.png"
            alt="Nekrasovka logo"
          />
        </div>
        <div>
          <img
            style={{ height: "32px", width: "32px" }}
            src="https://nekrasovka.ru/share/mail/vk.png"
            alt="VK logo"
          />
          &nbsp;
          <img
            style={{ height: "32px", width: "32px" }}
            src="https://nekrasovka.ru/share/mail/tg.png"
            alt="Telegram logo"
          />
        </div>
      </SignatureBody>
      <SignatureButtons>
        <div>
          <Button
            isDisabled={!isChanged}
            name="Сохранить"
            onClick={patchSignature}
          />
          <Button isDisabled={!isChanged} name="Отменить" onClick={getMail} />
        </div>
        <div>
          <span>
            Если у вас в каком-то ящике необходимо использовать специальную
            подпись и вы отключили для этого ящика общую подпись, вы можете
            скопировать готовую подпись отсюда и поправить ее при необходимости
            в настройках ящика.
          </span>
          <Button name="Скопировать подпись" onClick={handleCopySignature} />
        </div>
      </SignatureButtons>
    </Container>
  );
};

export default Signature;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const SignatureBody = styled.div`
  max-height: fit-content;
  padding: 15px;
  font-size: 14px;
  color: #222222;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
`;

const SignatureButtons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  button:disabled {
    opacity: 0.5;
  }

  > div {
    display: flex;
    column-gap: 15px;

    :nth-child(2) {
      > span {
        font-size: 14px;
        color: rgb(119, 119, 119);
      }
    }
  }

  @media (min-width: 701px) {
    > div {
      :nth-child(2) {
        button {
          white-space: nowrap;
        }
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      :nth-child(1) {
        button {
          width: 100%;
        }
      }
    }
  }
`;
